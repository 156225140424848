footer{
    background-color: rgb(7, 1, 75);
    color: white;
    padding: 70px 20px;
}

footer .footer-content{
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
}
.footer-content ul li h2{
    color: gold;
    padding: 15px 0;
}
.footer-content ul li p{
    width: 350px;
}
.footer-content ul li img{
    width: 400px;
    object-fit: cover;

}

footer .footer-content .footer-logo{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}
footer .footer-content .footer-logo h1{
    padding: 15px 0;
}
footer .footer-content .footer-logo img{
    width: 100px;
}
footer .footer-content ul li{
    line-height: 27px;
    cursor: pointer;
}

footer .social-media{
    display:flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 50px;
}
footer .social-media .fa {
  padding: 15px;
  font-size: 30px;
  width: 30px;
  height: 30px;
  border-radius: 100%;
  text-align: center;
  text-decoration: none;
  margin: 20px 15px;
  background-color: grey;
  color: white;
}
@media(max-width:768px){
    footer .footer-content{
        flex-direction: column;
        align-items: start;
    }
    footer .footer-content .footer-logo img{
        width: 100px;
        margin-left: 20px;
    }
    .footer-content ul li img{
        width: 350px;
    }
    footer .social-media .fa {
        padding: 15px;
        font-size: 30px;
        width: 25px;
        height: 25px;
        border-radius: 100%;
        text-align: center;
        text-decoration: none;
        margin: 20px 15px;
        background-color: grey;
        color: white;
      }
    
}