.testimonials {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 0 50px 0;
  background-color: #fff;
}

.testimonials h1 {
  margin-top: 40px;
  margin-bottom: 40px;
  font-size:40px;
  text-transform: capitalize;
  color: blue;
}

.slideshow-t {
  position: relative;
  width: 80%;
  max-width: 1200px;
  margin: auto;
  overflow: hidden;
}

.slideshow-testimonials-t {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
}


.testimonial.active {
  display: block;
}

.review {
  font-size: 1.2em;
  margin-bottom: 10px;
  color: #333;
}

.name {
  font-size: 1em;
  color: #777;
}

button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
  cursor: pointer;
}

button img {
  width: 30px;
  height: 30px;
}

button:first-of-type {
  left: 10px;
}

button:last-of-type {
  right: 10px;
}

/* Dot indicators */
.dot-container {
  display: flex;
  justify-content: center;
  margin-top: 15px;
}

.dot {
  height: 10px;
  width: 10px;
  margin: 0 5px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
  cursor: pointer;
}

.dot.active {
  background-color: #001f6c;
}

/* Responsive Design */
@media (max-width: 768px) {
  .slideshow-t {
    width: 90%;
  }

  .review {
    font-size: 1em;
  }

  .name {
    font-size: 0.9em;
  }

  button img {
    width: 25px;
    height: 25px;
  }
}

@media (max-width: 480px) {
  .slideshow-t {
    width: 100%;
  }

  .review {
    font-size: 0.9em;
  }

  .name {
    font-size: 0.8em;
  }

  button img {
    width: 20px;
    height: 20px;
  }
}
