.courses-title{
    text-align: center;
    color: Blue;
    font-size: 40px;
    text-transform:capitalize;
    cursor: pointer;
    transition: 1s ease; 
}
.cards-container {
  margin-bottom: 30px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}
/* Card */
.card {
  width: 260px;
  height: 260px;
  margin: 50px 30px;
  position: relative;
  transform-style: preserve-3d;
  transition: 1s ease;
}

.card:hover {
  transform: perspective(400px) rotateY(180deg);
}

.card_front,
.card_back {
  position: absolute;
  height: 100%;
  width: 100%;
  border-radius: 6px;
  backface-visibility: hidden;
  font-family: sans-serif;
  font-size: 20px;
  text-align: center;
  align-self: center;
  padding: 10px;
  padding-top: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
}


.card_front {
  background: rgba( 255, 255, 255, 0.25 );
  box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
  
}
.card_front img{
  width: 90%;
}

.card_back {
  background-color: rgb(0, 122, 245);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transform: rotateY(180deg);
}
.card_back h3{
  font-weight: bolder;
  font-size: 30px;
  margin: 10px 0;
}
.card_back a{
  color: rgb(255, 255, 255);
  background-color: rgb(20, 0, 110);
  padding: 20px;
  border-radius: 5px;
  text-decoration: none;
  width: 100%;
  margin: 10px 0;
}
.card_back a:hover{
  transition: 1s ease;
  background-color: rgb(0, 70, 144);
  color: rgb(245, 245, 245);
}
.card_back p {
  color: white;
  margin: 5px 0;
  margin: 10px 0;
}
