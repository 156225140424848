.number-container{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    background-color: white;
}
.number-container img{
    width: 70px;
    height: 70px;
    cursor: pointer;
    transition: transform 0.3s ease;
}
.number-container .counter-text{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 10px 0;
    padding: 40px 20px;
}
.number-container h2{
    font-size: 35px;
  
}
.number-container p{
    font-size: 25px;
}